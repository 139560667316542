import Cookies from 'js-cookie';

import { COOKIE_NAME } from '@shared/constants/cookies';

import { decodeJwt } from '../jwt';

export const readAccessTokenForCurrentPublisher = () => {
  return Cookies.get(COOKIE_NAME.PUBLISHER_ACCESS_TOKEN);
};

export const readRefreshTokenForCurrentPublisher = () => {
  return Cookies.get(COOKIE_NAME.PUBLISHER_REFRESH_TOKEN);
};

export const setAccessTokenForCurrentPublisher = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.PUBLISHER_ACCESS_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const setRefreshTokenForCurrentPublisher = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.PUBLISHER_REFRESH_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const removeAccessTokenForCurrentPublisher = () => {
  Cookies.remove(COOKIE_NAME.PUBLISHER_ACCESS_TOKEN);
};

export const removeRefreshTokenForCurrentPublisher = () => {
  Cookies.remove(COOKIE_NAME.PUBLISHER_REFRESH_TOKEN);
};

export const readSkipBusinessDocStepCookie = (): string[] => {
  const emailsStr = Cookies.get(COOKIE_NAME.PUBLISHER_SKIP_BUSINESS_DOC_STEP);
  if (!emailsStr) return [];

  const emails = JSON.parse(emailsStr);
  return Array.isArray(emails) ? emails : [];
};

export const setSkipBusinessDocStepCookie = (email: string) => {
  const existingEmails = readSkipBusinessDocStepCookie();
  if (!existingEmails.includes(email)) {
    const updatedEmails = [...existingEmails, email];
    Cookies.set(
      COOKIE_NAME.PUBLISHER_SKIP_BUSINESS_DOC_STEP,
      JSON.stringify(updatedEmails)
    );
  }
};
