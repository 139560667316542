import { IFetchParams } from '@interfaces';
import { RefundRequestStatus } from '@interfaces/admin/refund';

import { formatEndDate, formatStartDate } from '@features/utils';

import config from '@shared/config';
import {
  ADMIN_LOGIN,
  PUBLISHER_LOGIN,
} from '@shared/utils';
import {
  readRefreshTokenForCurrentAdmin,
  readRefreshTokenForCurrentPublisher,
  readRefreshTokenForCurrentUser,
  removeAccessTokenForCurrentAdmin,
  removeAccessTokenForCurrentPublisher,
  removeAccessTokenForCurrentUser,
  removeRefreshTokenForCurrentAdmin,
  removeRefreshTokenForCurrentPublisher,
  removeRefreshTokenForCurrentUser,
  setAccessTokenForCurrentAdmin,
  setAccessTokenForCurrentPublisher,
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentAdmin,
  setRefreshTokenForCurrentPublisher,
  setRefreshTokenForCurrentUser,
} from '@shared/utils/cookies/client';

import {
  ADMIN_REFRESH_TOKEN_URL,
  PUBLISHER_REFRESH_TOKEN_URL,
  USER_REFRESH_TOKEN_URL,
} from './constants';

export const handleClientSideUnauthorizedRefreshTokenRequest = async (
  url: string
) => {
  if (url.includes(config.adminApiUrl)) {
    await removeAccessTokenForCurrentAdmin();
    await removeRefreshTokenForCurrentAdmin();
    window.location.href = ADMIN_LOGIN;
  } else if (url.includes(config.publisherApiUrl)) {
    await removeAccessTokenForCurrentPublisher();
    await removeRefreshTokenForCurrentPublisher();
    window.location.href = PUBLISHER_LOGIN;
  } else {
    await removeAccessTokenForCurrentUser();
    await removeRefreshTokenForCurrentUser();
    window.location.href = '/';
  }
};

export const getDomainRefreshToken = async (url: string) => {
  switch (true) {
    case url.includes(config.adminApiUrl):
      return readRefreshTokenForCurrentAdmin();
    case url.includes(config.userApiUrl):
      return readRefreshTokenForCurrentUser();
    case url.includes(config.publisherApiUrl):
      return readRefreshTokenForCurrentPublisher();
    default:
      break;
  }
};

export const setDomainRefreshTokens = async (
  url: string,
  refreshToken: string,
  accessToken: string
) => {
  if (url.includes(config.adminApiUrl)) {
    await setAccessTokenForCurrentAdmin(accessToken);
    await setRefreshTokenForCurrentAdmin(refreshToken);
  } else if (url.includes(config.publisherApiUrl)) {
    await setAccessTokenForCurrentPublisher(accessToken);
    await setRefreshTokenForCurrentPublisher(refreshToken);
  } else {
    await setAccessTokenForCurrentUser(accessToken);
    await setRefreshTokenForCurrentUser(refreshToken);
  }
};

export const getRefreshTokenUrl = (url: string) => {
  switch (true) {
    case url.includes(config.adminApiUrl):
      return ADMIN_REFRESH_TOKEN_URL;
    case url.includes(config.userApiUrl):
      return USER_REFRESH_TOKEN_URL;
    case url.includes(config.publisherApiUrl):
      return PUBLISHER_REFRESH_TOKEN_URL;
    default:
      return '';
  }
};

export const appendParamsToUrl = (
  url: string,
  params?: Record<string, string | number | (string | number)[] | undefined>
): string => {
  if (!params) return url;

  const urlWithParams = new URL(url);

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((val) => {
        urlWithParams.searchParams.append(key, val.toString());
      });
    } else if (!!value) {
      urlWithParams.searchParams.append(key, value.toString());
    }
  });

  return urlWithParams.toString();
};

export const generateRefundStatus = (
  status: string,
  defaultRefundRequestStatuses: RefundRequestStatus[]
) => {
  return {
    'filter.status':
      status && status !== 'all'
        ? `$in:${status as string}`
        : `$in:${defaultRefundRequestStatuses.toString()}`,
  };
};

export const formatDateParams = (params?: IFetchParams, timeZone?: string) => {
  return {
    ...params,
    ...(params?.startDate && {
      startDate: formatStartDate(params.startDate as string, timeZone),
    }),
    ...(params?.endDate && {
      endDate: formatEndDate(params.endDate as string, timeZone),
    }),
  };
};
