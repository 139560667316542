import {
  readAccessTokenForCurrentAdmin,
  readRefreshTokenForCurrentAdmin,
  removeAccessTokenForCurrentAdmin,
  removeRefreshTokenForCurrentAdmin,
  setAccessTokenForCurrentAdmin,
  setRefreshTokenForCurrentAdmin,
} from './adminCookies-server';
import {
  readAccessTokenForCurrentPublisher,
  readRefreshTokenForCurrentPublisher,
  readSkipBusinessDocStepCookie,
  removeAccessTokenForCurrentPublisher,
  removeRefreshTokenForCurrentPublisher,
  setAccessTokenForCurrentPublisher,
  setRefreshTokenForCurrentPublisher,
  setSkipBusinessDocStepCookie,
} from './publisherCookies-server';
import {
  readAccessTokenForCurrentUser,
  readRefreshTokenForCurrentUser,
  removeAccessTokenForCurrentUser,
  removeRefreshTokenForCurrentUser,
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentUser,
} from './userCookies-server';

export {
  // Admin cookie exports
  readAccessTokenForCurrentAdmin,
  readRefreshTokenForCurrentAdmin,
  setAccessTokenForCurrentAdmin,
  setRefreshTokenForCurrentAdmin,
  removeAccessTokenForCurrentAdmin,
  removeRefreshTokenForCurrentAdmin,

  // User cookie exports
  readAccessTokenForCurrentUser,
  readRefreshTokenForCurrentUser,
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentUser,
  removeAccessTokenForCurrentUser,
  removeRefreshTokenForCurrentUser,

  // Publisher cookie exports
  readAccessTokenForCurrentPublisher,
  readRefreshTokenForCurrentPublisher,
  setAccessTokenForCurrentPublisher,
  setRefreshTokenForCurrentPublisher,
  removeAccessTokenForCurrentPublisher,
  removeRefreshTokenForCurrentPublisher,
  readSkipBusinessDocStepCookie,
  setSkipBusinessDocStepCookie,
};
