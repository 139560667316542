import { refreshDomainToken } from '@api/requestBuilder/refreshToken/refreshTokenApi';

import {
  readAccessTokenForCurrentAdmin,
  readAccessTokenForCurrentPublisher,
  readAccessTokenForCurrentUser,
  readRefreshTokenForCurrentAdmin,
  readRefreshTokenForCurrentPublisher,
  readRefreshTokenForCurrentUser,
  setAccessTokenForCurrentAdmin,
  setAccessTokenForCurrentPublisher,
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentAdmin,
  setRefreshTokenForCurrentPublisher,
  setRefreshTokenForCurrentUser,
} from '@shared/utils/cookies/client';

import {
  ADMIN_REFRESH_TOKEN_URL,
  PUBLISHER_REFRESH_TOKEN_URL,
  USER_REFRESH_TOKEN_URL,
} from '../constants';
import { RequestBuilder } from '../requestBuilder';

export class ClientRequestBuilder<T> extends RequestBuilder<T> {
  constructor(url?: string);
  constructor(callback: (requestInit: RequestInit) => Promise<T>);

  constructor(
    arg: string | ((requestInit: RequestInit) => Promise<T>) | undefined
  ) {
    super(arg);
  }

  private async authenticateUser(
    readAccessToken: () => string | undefined,
    readRefreshToken: () => string | undefined,
    setAccessToken: (token: string) => void,
    setRefreshToken: (token: string) => void,
    refreshTokenUrl: string
  ) {
    const accessToken = await readAccessToken();
    const refreshToken = await readRefreshToken();

    let token = accessToken;

    //This is a quick fix for the issue involving fetching products on the homepage with and without credentials.
    if (!refreshToken && !accessToken) return;

    if (!accessToken && refreshToken) {
      const { data } = await refreshDomainToken(refreshToken, refreshTokenUrl);

      setAccessToken(data.token);
      setRefreshToken(data.refreshToken);
      token = data.token;
    }

    this.requestInit = {
      ...this.requestInit,
      headers: {
        ...this.requestInit?.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    return this;
  }

  public async authenticate() {
    return this.authenticateUser(
      readAccessTokenForCurrentUser,
      readRefreshTokenForCurrentUser,
      setAccessTokenForCurrentUser,
      setRefreshTokenForCurrentUser,
      USER_REFRESH_TOKEN_URL
    );
  }

  public async authenticatePublisher() {
    return this.authenticateUser(
      readAccessTokenForCurrentPublisher,
      readRefreshTokenForCurrentPublisher,
      setAccessTokenForCurrentPublisher,
      setRefreshTokenForCurrentPublisher,
      PUBLISHER_REFRESH_TOKEN_URL
    );
  }

  public async authenticateAdmin() {
    return this.authenticateUser(
      readAccessTokenForCurrentAdmin,
      readRefreshTokenForCurrentAdmin,
      setAccessTokenForCurrentAdmin,
      setRefreshTokenForCurrentAdmin,
      ADMIN_REFRESH_TOKEN_URL
    );
  }
}
