import Cookies from 'js-cookie';

import { COOKIE_NAME } from '@shared/constants/cookies';

import { decodeJwt } from '../jwt';

export const readAccessTokenForCurrentUser = () => {
  return Cookies.get(COOKIE_NAME.ACCESS_TOKEN);
};

export const readRefreshTokenForCurrentUser = () => {
  return Cookies.get(COOKIE_NAME.REFRESH_TOKEN);
};

export const setAccessTokenForCurrentUser = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.ACCESS_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const setRefreshTokenForCurrentUser = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.REFRESH_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const removeAccessTokenForCurrentUser = () => {
  Cookies.remove(COOKIE_NAME.ACCESS_TOKEN);
};

export const removeRefreshTokenForCurrentUser = () => {
  Cookies.remove(COOKIE_NAME.REFRESH_TOKEN);
};
