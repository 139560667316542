import Cookies from 'js-cookie';

import { COOKIE_NAME } from '@shared/constants/cookies';

import { decodeJwt } from '../jwt';

export const readAccessTokenForCurrentAdmin = () => {
  return Cookies.get(COOKIE_NAME.ADMIN_ACCESS_TOKEN);
};

export const readRefreshTokenForCurrentAdmin = () => {
  return Cookies.get(COOKIE_NAME.ADMIN_REFRESH_TOKEN);
};

export const setAccessTokenForCurrentAdmin = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.ADMIN_ACCESS_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const setRefreshTokenForCurrentAdmin = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  Cookies.set(COOKIE_NAME.ADMIN_REFRESH_TOKEN, jwt, {
    expires: new Date(decodedJwt.exp * 1000),
  });
};

export const removeAccessTokenForCurrentAdmin = () => {
  Cookies.remove(COOKIE_NAME.ADMIN_ACCESS_TOKEN);
};

export const removeRefreshTokenForCurrentAdmin = () => {
  Cookies.remove(COOKIE_NAME.ADMIN_REFRESH_TOKEN);
};